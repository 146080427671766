<template>
  <div>
    <v-container id="user-profile" fluid tag="section">
      <v-row justify="center">
        <v-col cols="12" md="8">
          <base-material-card icon="mdi-account-outline">
            <template #after-heading>
              <div class="font-weight-light card-title mt-2">
                Edit Profile
                <span class="body-1">— Complete your profile</span>
              </div>
            </template>

            <v-form @submit.stop.prevent="onSubmit">
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      label="Company ID "
                      disabled
                      :value="currentUser.company_relation"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      class="purple-input"
                      label="User Name"
                      :value="currentUser.username"
                      disabled
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.firstName"
                      :error-messages="firstNameErrors"
                      label="First Name"
                      class="purple-input"
                      @input="$v.form.firstName.$touch()"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="form.lastName"
                      :error-messages="lastNameErrors"
                      label="Last Name"
                      class="purple-input"
                      @input="$v.form.lastName.$touch()"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.businesstitle"
                      :error-messages="businessTitleErrors"
                      label="Business Title"
                      class="purple-input"
                      @input="$v.form.businesstitle.$touch()"
                    />

                    <v-checkbox
                      v-model="form.show_businesstitle"
                      label="Show in Contacts"
                      class="mt-0"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.contactphone"
                      :error-messages="contactPhoneErrors"
                      label="Phone #"
                      class="purple-input"
                      name="contact_phone"
                      @input="$v.form.contactphone.$touch()"
                    />

                    <v-checkbox
                      v-model="form.show_contactphone"
                      label="Show in Contacts"
                      class="mt-0"
                    />
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="form.email"
                      disabled
                      label="Email Address (can not change)"
                      class="purple-input"
                    />

                    <v-checkbox
                      v-model="form.show_contactemail"
                      label="Show in Contacts"
                      class="mt-0"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-checkbox
                      v-model="form.get_notice"
                      label="Receive email notices of unread company messages"
                      class="mt-0"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-switch
                      v-model="form.expert"
                      inset
                      label="Enable Expert Mode"
                    ></v-switch>
                  </v-col>

                  <v-divider class="mx-4"></v-divider>

                  <v-divider class="mx-4"></v-divider>

                  <v-col cols="12" class="text-right">
                    <v-dialog v-model="dialog" persistent max-width="600px">
                      <template #activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                          Change Password
                        </v-btn>
                      </template>
                      <v-card>
                        <change-user-password @close-modal="closeModal" />
                      </v-card>
                    </v-dialog>
                    <v-btn
                      ref="kt_login_signup_submit"
                      type="submit"
                      color="success"
                      class="mr-0"
                    >
                      Update Profile
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </base-material-card>
        </v-col>

        <v-col cols="12" md="4">
          <base-material-card
            class="v-card-profile"
            avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
          >
            <div class="text-center">
              <i
                aria-hidden="true"
                class="v-icon notranslate mdi mdi-format-quote-close theme--light black--text test-center"
                style="font-size: 40px;"
              ></i>
            </div>

            <v-card-text class="text-center">
              <h4 class="display-2 font-weight-light mb-3 black--text">
                A wise man once said:
              </h4>

              <p class="font-weight-light grey--text">
                If a tree falls in the woods and you did not have a valid
                contact info, did it make a sound...
              </p>
            </v-card-text>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// import { UPDATE_USER } from '@/core/services/store/auth.module'
import UpdateUser from '@/graphql/mutations/UpdateUser.gql'
import ChangeUserPassword from '@/view/pages/account/ChangeUserPassword'
import Swal from 'sweetalert2'
export default {
  name: 'Profile',
  components: {
    ChangeUserPassword,
  },
  mixins: [validationMixin],
  data() {
    return {
      dialog: false,
      tabIndex: 0,
      form: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        contactemail: '',
        contactphone: '',
        businesstitle: '',
        show_contactemail: true,
        show_contactphone: true,
        show_businesstitle: true,
        get_notice: true,
        expert: false,
      },
    }
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      businesstitle: {
        required,
      },
      contactphone: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    firstNameErrors() {
      const errors = []
      if (!this.$v.form.firstName.$dirty) {
        return errors
      }
      !this.$v.form.firstName.required && errors.push('This field is required')
      return errors
    },
    lastNameErrors() {
      const errors = []
      if (!this.$v.form.lastName.$dirty) {
        return errors
      }
      !this.$v.form.lastName.required && errors.push('This field is required')
      return errors
    },
    businessTitleErrors() {
      const errors = []
      if (!this.$v.form.businesstitle.$dirty) {
        return errors
      }
      !this.$v.form.businesstitle.required &&
        errors.push('This field is required')
      return errors
    },
    contactPhoneErrors() {
      const errors = []
      if (!this.$v.form.contactphone.$dirty) {
        return errors
      }
      !this.$v.form.contactphone.required &&
        errors.push('This field is required')
      return errors
    },
  },
  mounted() {
    setTimeout(() => {
      this.form.email = this.currentUser.email
      if (this.currentUser.contact_email != null)
        this.form.contactemail = this.currentUser.contact_email
      else this.form.contactemail = this.currentUser.email
      this.form.contactphone = this.currentUser.contact_phone
      this.form.firstName = this.currentUser.FirstName
      this.form.lastName = this.currentUser.LastName
      this.form.businesstitle = this.currentUser.business_title
      if (this.currentUser.show_contact_email != null)
        this.form.show_contactemail = this.currentUser.show_contact_email
      if (this.currentUser.show_contact_phone != null)
        this.form.show_contactphone = this.currentUser.show_contact_phone
      if (this.currentUser.show_business_title != null)
        this.form.show_businesstitle = this.currentUser.show_business_title
      if (this.currentUser.get_notice != null)
        this.form.get_notice = this.currentUser.get_notice
      if (this.currentUser.expert != null)
        this.form.expert = this.currentUser.expert
    }, 1000)
  },

  methods: {
    closeModal(f) {
      this.dialog = f
    },
    // validateState(name) {
    //   const { $dirty, $error } = this.$v.form[name]
    //   return $dirty ? !$error : null
    // },
    /**
     * Reset config
     */
    reset(event) {
      event.preventDefault()
      // remove existing saved config
      localStorage.removeItem('config')
      window.location.reload()
    },

    /**
     * Submit form
     * @param event
     */
    onSubmit(e) {
      e.preventDefault()
      this.$v.$touch()
      if (!this.$v.$invalid) {
        // set spinner to submit button
        const submitButton = this.$refs['kt_login_signup_submit']

        // dummy delay
        setTimeout(async () => {
          await this.$apollo.mutate({
            mutation: UpdateUser,
            variables: {
              id: this.currentUser.id,
              email: this.form.email,
              FirstName: this.form.firstName,
              LastName: this.form.lastName,
              contact_email: this.form.contactemail,
              contact_phone: this.form.contactphone,
              business_title: this.form.businesstitle,
              show_contact_email: this.form.show_contactemail,
              show_contact_phone: this.form.show_contactphone,
              show_business_title: this.form.show_businesstitle,
              get_notice: this.form.get_notice,
              expert: this.form.expert,
            },
          })
          // this.$store
          //   .dispatch(UPDATE_USER, {
          //     // email: email,
          //     // password: password,
          //     contact_email: this.form.contactemail,
          //     contact_phone: this.form.contactphone,
          //     business_title: this.form.businesstitle,
          //     FirstName: this.form.FirstName,
          //     LastName: this.form.LastName,
          //     show_contact_email: this.form.show_contactemail,
          //     show_contact_phone: this.form.show_contactphone,
          //     show_business_title: this.form.show_businesstitle,
          //     get_notice: this.form.get_notice,
          //     expert: this.form.expert,
          //   })
          //   .then(() => {
          // window.location.reload()
          Swal.fire({
            title: '',
            text: 'Saved successfully.',
            icon: 'success',
            confirmButtonClass: 'btn btn-secondary',
          })
          //   })

          submitButton.classList.remove(
            'spinner',
            'spinner-light',
            'spinner-right'
          )
        }, 2000)
      }
      // const email = this.$v.form.email.$model
      // const password = this.$v.form.password.$model
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      <span class="headline">Change Password</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="Current Password"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="newPassword"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="input-10-1"
              label="New Password"
              counter
              @click:append="show2 = !show2"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="confirmPassword"
              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show3 ? 'text' : 'password'"
              name="input-10-1"
              label="Confirm Password"
              counter
              @click:append="show3 = !show3"
            ></v-text-field>
          </v-col>
          <div>
            <p v-if="submitStatus === 'OK'" class="typo__p"></p>
            <p v-if="submitStatus === 'ERROR'" class="typo__p">
              Please fill the form correctly.
            </p>
            <p v-if="submitStatus === 'PENDING'" class="typo__p">Sending...</p>
          </div>
        </v-row>
      </v-container>
      <small>*indicates required field</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeModal"> Close </v-btn>
      <v-btn
        ctype="submit"
        color="success"
        class="mr-0"
        :disabled="submitStatus === 'PENDING'"
        @click="onSubmit"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Swal from 'sweetalert2'
import axios from 'axios'
export default {
  mixins: [validationMixin],
  data() {
    return {
      submitStatus: null,
      show1: false,
      show2: false,
      show3: false,
      password: '',
      newPassword: '',
      confirmPassword: '',
    }
  },
  validations: {
    password: {
      required,
    },
    newPassword: {
      required,
    },
    confirmPassword: {
      required,
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    closeModal() {
      const dialog = false
      this.$emit('close-modal', dialog)
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'

        setTimeout(() => {
          axios
            .put('/updatePassword', {
              identifier: this.currentUser.email,
              password: this.password,
              newPassword: this.newPassword,
              confirmPassword: this.confirmPassword,
            })
            .then(() => {
              this.submitStatus = 'OK'
              const dialog = false
              this.password = ''
              this.newPassword = ''
              this.confirmPassword = ''
              this.$emit('close-modal', dialog)
              Swal.fire({
                title: '',
                text: 'Saved successfully.',
                icon: 'success',
                confirmButtonClass: 'btn btn-secondary',
              })
            })
            .catch(() => {
              // handle error in componen

              const dialog = false
              this.$emit('close-modal', dialog)
              this.password = ''
              this.newPassword = ''
              this.confirmPassword = ''
              this.submitStatus = 'OK'
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Error invalid Password',
              })
            })
        }, 500)
      }
    },
  },
}
</script>
